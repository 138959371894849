













































































import Component, { mixins } from "vue-class-component";
import WidgetMixins from "../../../mixins/WidgetMixins";
import DialogMixins from "../../../mixins/DialogMixins";
import { currencyFormat, loadView, loadWidget } from "@/utils/helpers";
import { namespace } from "vuex-class";
import { IOrders, IPaginate, IStore, IUser } from "@/types/types";
import timestamp from "@/filters/timestamp";
import mediumZoom from "medium-zoom";
import { Watch } from "vue-property-decorator";
import moment from "moment";
import { IFeedback } from "@/store/modules/feedback";

const feedbacksModule = namespace("feedbacks");
const authModule = namespace("auth");
@Component({
  name: "OrderIndex",
  components: {
    InternetConnection: loadWidget("pages/InternetConnection"),
    ToolBar: loadWidget("widgets/ToolBar"),
    SnackBar: loadWidget("widgets/SnackBar")
  },
  filters: {
    currencyFormat,
    timestamp
  }
})
export default class OrderIndex extends mixins(WidgetMixins, DialogMixins) {
  @feedbacksModule.Getter("getAllFeedback") orders!: IFeedback[];
  @feedbacksModule.Getter("getPagination") paginate!: IPaginate;
  @authModule.Getter("getUserDetails") details!: IUser & IStore;
  menu: boolean = false;

  headers: Array<{ text: string; value: string }> = [
    {
      text: "Date",
      value: "createdAt"
    },
    {
      text: "MSISDN",
      value: "msisdn"
    },
    {
      text: "Content",
      value: "comment"
    }
  ];

  options: any = {};
  pagination: IPaginate = {
    page: 1,
    total: 0,
    itemsPerPage: 15
  };

  @Watch("paginate")
  onPaginationSet(payload: IPaginate): void {
    this.pagination = { ...payload };
  }

  @Watch("options")
  onPageChange(options: IPaginate): void {
    this.$store.dispatch("feedbacks/list", {
      query: `?page=${options?.page}&size=${options?.itemsPerPage}`
    });
  }

  created(): void {
    this.$store.dispatch("feedbacks/list", {
      query: `?page=1&size=20`
    });
    this.$store.dispatch("auth/me");
  }

  mounted(): void {
    mediumZoom(".productPreview");
  }
}
